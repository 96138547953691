.unit-title-v1 {
	.title-line {

		@media (min-width:993px) {
			display: flex;

		}



		.quality {
			@media (min-width:993px) {
				padding-left: 10px;
			}
		}

		.stars {
			img {
				display: inline-block;
				height: 30px;
				padding-bottom: 5px;

			}
		}
	}
}