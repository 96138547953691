.contact-page-layout-v1 {
	margin-top: var(--page-margin-top);

	.contact-img {
		margin-top: 20px;

		img {
			border-radius: 10px;
			max-width: 601px;
			width: 100%;
		}
	}

	.form {
		max-width: 992px;
	}
}