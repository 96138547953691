.nofound-v1 {
	position: relative;
	height: 100vh;
	min-height: 100vh;

	@media (max-height: 600px) {
		min-height: 680px;

	}

	.picture {
		height: 100vh;
		min-height: var(--index-slide-show-height);

		@media (max-height: 600px) {
			min-height: 680px;

		}

		.img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	.nofound-header {
		position: absolute;
		top: 40vh;
		font-family: var(--font-family-main);
		width: 100%;
		z-index: 3;
		padding: 0 5px;

		@media (max-height: 600px) {
			top: 200px;

		}

		.nofound-text {
			text-align: center;
			width: 100%;

			.nofound-headline {
				width: 100%;
			}

			h1 {
				font-family: var(--hero-header-font-family);
				font-size: 3.5rem;
				color: var(--font-color-main);
				/*text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);*/
				font-weight: 400;
				margin-bottom: 0px;

				@media (min-width: 993px) {
					font-size: 4.5rem;
				}


			}

			.nofound-subtitle {
				font-family: var(--hero-header-sub-title-font-family);
				font-size: 1.5rem;
				color: var(--font-color-main);
				/*text-shadow: 0 0.1rem 1rem rgba(0, 0, 0, .5);*/

				@media (min-width: 993px) {
					font-size: 2rem;
				}


			}
		}
	}
}