.footer-v1 {

	.main-footer {

		background-color: var(--footer-bg-color);
		color: var(--footer-font-color);
		/*border-top: 1px solid var(--footer-border-top-color);*/
		box-shadow: 0px 0 1px rgb(0 0 0 / 70%);



		padding-left: 5vw;
		padding-right: 5vw;
		margin-top: -2px;

		.headline {
			font-size: 16px;
			color: var(--footer-font-color);
			font-weight: bold;
			text-transform: uppercase;
		}


		.logo {
			width: 200px;
			height: auto;
			margin-top: -40px;
			padding-bottom: 0px;
		}

		.openings {

			.fa {
				color: var(--footer-font-color);
			}

			.tel {
				font-size: 1.0rem;
			}

			.hint {
				max-width: 350px;
			}

		}

		a {
			color: var(--footer-font-color);
		}

		.social-icons {

			li {

				a {
					color: var(--footer-font-color);
				}

				.fa {
					font-size: 20px;
					color: var(--footer-font-color);

				}
			}

		}




		#button-addon1 {
			color: #ffc371;
		}

		.btn-link {
			background-color: var(--footer-btn-bg-color);

			.fa {
				color: var(--footer-icon-color);
			}
		}

		i {
			color: #ffc371;
		}

		.form-control::placeholder {
			font-size: 0.95rem;
			color: #aaa;
			font-style: italic;
		}

		.form-control.shadow-0:focus {
			box-shadow: none;
		}
	}

	.copyright {
		background-color: var(--footer-copyright-bg-color);
		color: var(--footer-copyright-font-color);
	}

}